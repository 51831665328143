import * as React from "react"
import {
  Box,
  Button,
  ButtonTheme,
  FormMessage,
  Input,
  Text,
  TextBold,
  ButtonIcon,
  FormSingleInput as Form,
} from "../../elements"
import { Colors, Icons } from "../../materials"
import { addEmailToSubscribers } from "../../utils/add-email-to-subscribers"
import { trackFormSubmitEvent } from "../../utils/track-ga-event"

type ErrorMessage = string | undefined
type Email = string

const ESC = 27

export const SubscriptionForm: React.FC<{
  successMessage?: string
}> = ({ children, successMessage = `You'll hear from us soon.` }) => {
  const [emailError, setEmailError] = React.useState<ErrorMessage>()
  const [email, setEmail] = React.useState<Email>("")
  const [error, setError] = React.useState<ErrorMessage>()
  const [submitted, setSubmitted] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const inputRef = React.useRef(null)
  const submitRef = React.useRef(null)
  const onSubmit: React.FormEventHandler = async event => {
    event.preventDefault()
    if (loading) {
      return
    }
    if (!email) {
      setEmailError("required")
      return
    }
    // check email validity heres
    try {
      setLoading(true)
      trackFormSubmitEvent("subscription-form")
      const response = await addEmailToSubscribers(email)
      if (!response.ok) {
        throw new Error("Something went wrong.")
      }
      setLoading(false)
      setError("")
      setSubmitted(true)
    } catch (error) {
      setLoading(false)
      setEmail("")
      setError(error.message)
    }
  }

  const showForm = !submitted && !error

  return (
    <>
      {showForm ? (
        <>
          <Form
            onSubmit={onSubmit}
            width={[1, 1, 1, "auto"]}
            display="flex"
            flexDirection={["column", "row"]}
            css={{ position: "relative", zIndex: 1 }}
          >
            <Box
              className="input-wrapper"
              display="flex"
              css={{ position: "relative", overflow: "hidden" }}
            >
              <Input
                ref={inputRef}
                type="email"
                placeholder="Your email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                disabled={loading}
                required
                size={25}
                aria-label="Your email address"
                aria-describedby="email-description"
                onKeyDown={event => {
                  if (event.keyCode === ESC) {
                    setEmail("")
                  }
                }}
              />
              <Box
                alignItems="center"
                justifyContent="center"
                px={1}
                className={`show-on-focus`}
              >
                <ButtonIcon
                  type="button"
                  aria-label="Clear email"
                  onClick={() => {
                    setEmail("")
                    inputRef.current && inputRef.current.focus()
                  }}
                  css={{
                    display: email.length === 0 ? "none" : "flex",
                  }}
                >
                  <Icons.SvgClose focusable={false} />
                </ButtonIcon>
              </Box>
            </Box>

            {/* SPACE on mobile */}
            <Box mt={1} display={["block", "none"]} />
            <Button
              disabled={loading || submitted}
              theme={ButtonTheme.MAIN}
              css={{ whiteSpace: "nowrap" }}
            >
              Join the waitlist
            </Button>
          </Form>
          {children}
        </>
      ) : null}
      {submitted ? (
        <Box>
          <FormMessage>
            <Box width={[45, 50, 70]} css={{ position: "absolute" }}>
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <g
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinejoin="round"
                >
                  <path d="M22.354,7.593l8.32746e-08,1.95975e-07c2.42988,5.71836 -0.235964,12.3238 -5.95432,14.7537c-5.71836,2.42988 -12.3238,-0.235964 -14.7537,-5.95432c-1.19467,-2.81147 -1.19467,-5.98789 1.66549e-07,-8.79936" />
                  <path d="M4.06,4.031l4.73802e-07,-4.72193e-07c4.3895,-4.3746 11.4905,-4.3746 15.88,9.44386e-07" />
                  <path d="M18.048,15l-1.65368e-07,3.33732e-07c-1.65566,3.34132 -5.70651,4.70782 -9.04783,3.05217c-1.32394,-0.656026 -2.39614,-1.72823 -3.05217,-3.05217" />
                  <path d="M9.782,4.251l-2.77862e-09,-2.91611e-09c-0.775684,-0.814068 -2.06443,-0.845184 -2.8785,-0.0695001c-0.0237226,0.0226041 -0.046896,0.0457775 -0.0695001,0.0695001l-1.263,1.306l-1.263,-1.306l8.77913e-08,9.2165e-08c-0.775173,-0.813792 -2.06328,-0.845098 -2.87707,-0.0699252c-0.0238713,0.0227385 -0.0471865,0.0460537 -0.069925,0.069925l6.802e-08,-7.08604e-08c-0.817174,0.851298 -0.817174,2.1957 -1.3604e-07,3.047l3.762,3.891l2.39652e-08,2.52858e-08c0.235548,0.248528 0.627969,0.259051 0.876497,0.0235026c0.00804284,-0.00762277 0.0158799,-0.0154598 0.0235026,-0.0235026l3.759,-3.889l9.05833e-08,-9.47408e-08c0.815373,-0.852796 0.815373,-2.1962 -1.81167e-07,-3.049Z" />
                  <path d="M22.639,4.251l8.77913e-08,9.2165e-08c-0.775173,-0.813792 -2.06328,-0.845098 -2.87707,-0.0699252c-0.0238713,0.0227385 -0.0471865,0.0460537 -0.069925,0.069925l-1.263,1.306l-1.263,-1.306l-2.77862e-09,-2.91611e-09c-0.775684,-0.814068 -2.06443,-0.845184 -2.8785,-0.0695001c-0.0237226,0.0226041 -0.046896,0.0457775 -0.0695001,0.0695001l4.62124e-08,-4.83941e-08c-0.814099,0.852533 -0.814099,2.19447 -9.24248e-08,3.047l3.762,3.891l2.39652e-08,2.52858e-08c0.235548,0.248528 0.627969,0.259051 0.876497,0.0235026c0.00804284,-0.00762277 0.0158799,-0.0154598 0.0235026,-0.0235026l3.759,-3.889l7.66436e-08,-7.97434e-08c0.818455,-0.851558 0.818455,-2.19744 -1.53287e-07,-3.049Z" />
                </g>
              </svg>
            </Box>
            <Box pl={[8, 8, 9]}>
              <TextBold as="strong" fontSize={6}>
                YAY!
              </TextBold>
              <br />
              <Text as="span" fontSize={4} mt={2}>
                {successMessage}
              </Text>
            </Box>
          </FormMessage>
        </Box>
      ) : null}

      {emailError ? (
        <Box>
          <FormMessage
            bg={Colors.oc.red[3]}
            color={Colors.oc.red[8]}
            css={{
              border: `1px solid ${Colors.oc.red[5]}`,
            }}
          >
            <Box width={[45, 50, 70]} css={{ position: "absolute" }}>
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <g
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinejoin="round"
                >
                  <path d="M12,13.5c-0.828,0 -1.5,0.5 -1.5,1.125v4.5c0,0.621 0.672,1.125 1.5,1.125c0.828,0 1.5,-0.5 1.5,-1.125v-4.5c0,-0.625 -0.672,-1.125 -1.5,-1.125Z" />
                  <path d="M20.392,16.549c1.555,-1.079 2.858,-1.8 2.858,-5.527c0,-6.214 -5.037,-10.272 -11.25,-10.272c-6.213,0 -11.25,4.058 -11.25,10.272c0,3.685 1.276,4.433 2.809,5.493" />
                  <path d="M7.511,21.086l4.11142e-07,6.10499e-07c0.995932,1.47885 2.71172,2.30597 4.489,2.164l-1.77179e-07,-1.41677e-08c1.79441,0.143485 3.52513,-0.699088 4.519,-2.2" />
                  <path d="M0.75,23.25c0,0 2.935,-0.978 2.935,-4.891c0,-3.913 -1.468,-4.4 -0.489,-5.381c0.979,-0.981 4.4,1.957 4.4,5.381l-1.56145e-07,-3.68395e-06c0.0697354,1.64527 -0.0949131,3.2921 -0.488998,4.891" />
                  <path d="M23.25,23.25c0,0 -2.935,-0.978 -2.935,-4.891c0,-3.913 1.468,-4.4 0.489,-5.381c-0.979,-0.981 -4.4,1.957 -4.4,5.381l1.54865e-07,-3.65374e-06c-0.0697354,1.64527 0.0949131,3.2921 0.488998,4.891" />
                  <path d="M8.45083,7.40901c0.732233,0.87868 0.732233,2.3033 0,3.18198c-0.732233,0.87868 -1.91942,0.87868 -2.65165,0c-0.732233,-0.87868 -0.732233,-2.3033 0,-3.18198c0.732233,-0.87868 1.91942,-0.87868 2.65165,0" />
                  <path d="M18.2008,7.40901c0.732233,0.87868 0.732233,2.3033 0,3.18198c-0.732233,0.87868 -1.91942,0.87868 -2.65165,0c-0.732233,-0.87868 -0.732233,-2.3033 0,-3.18198c0.732233,-0.87868 1.91942,-0.87868 2.65165,0" />
                </g>
              </svg>
            </Box>
            <Box pl={[8, 8, 9]}>
              <TextBold as="strong" fontSize={[2, 3, 6]}>
                Oups!
              </TextBold>
              <br />
              <Text as="span" fontSize={[2, 3, 4]} mt={2}>
                {emailError}
              </Text>
            </Box>
          </FormMessage>
        </Box>
      ) : null}
      {error && (
        <Box>
          <FormMessage
            bg={Colors.oc.red[3]}
            color={Colors.oc.red[8]}
            css={{
              border: `1px solid ${Colors.oc.red[5]}`,
            }}
          >
            <Box width={[45, 50, 70]} css={{ position: "absolute" }}>
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <g
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinejoin="round"
                >
                  <path d="M12,13.5c-0.828,0 -1.5,0.5 -1.5,1.125v4.5c0,0.621 0.672,1.125 1.5,1.125c0.828,0 1.5,-0.5 1.5,-1.125v-4.5c0,-0.625 -0.672,-1.125 -1.5,-1.125Z" />
                  <path d="M20.392,16.549c1.555,-1.079 2.858,-1.8 2.858,-5.527c0,-6.214 -5.037,-10.272 -11.25,-10.272c-6.213,0 -11.25,4.058 -11.25,10.272c0,3.685 1.276,4.433 2.809,5.493" />
                  <path d="M7.511,21.086l4.11142e-07,6.10499e-07c0.995932,1.47885 2.71172,2.30597 4.489,2.164l-1.77179e-07,-1.41677e-08c1.79441,0.143485 3.52513,-0.699088 4.519,-2.2" />
                  <path d="M0.75,23.25c0,0 2.935,-0.978 2.935,-4.891c0,-3.913 -1.468,-4.4 -0.489,-5.381c0.979,-0.981 4.4,1.957 4.4,5.381l-1.56145e-07,-3.68395e-06c0.0697354,1.64527 -0.0949131,3.2921 -0.488998,4.891" />
                  <path d="M23.25,23.25c0,0 -2.935,-0.978 -2.935,-4.891c0,-3.913 1.468,-4.4 0.489,-5.381c-0.979,-0.981 -4.4,1.957 -4.4,5.381l1.54865e-07,-3.65374e-06c-0.0697354,1.64527 0.0949131,3.2921 0.488998,4.891" />
                  <path d="M8.45083,7.40901c0.732233,0.87868 0.732233,2.3033 0,3.18198c-0.732233,0.87868 -1.91942,0.87868 -2.65165,0c-0.732233,-0.87868 -0.732233,-2.3033 0,-3.18198c0.732233,-0.87868 1.91942,-0.87868 2.65165,0" />
                  <path d="M18.2008,7.40901c0.732233,0.87868 0.732233,2.3033 0,3.18198c-0.732233,0.87868 -1.91942,0.87868 -2.65165,0c-0.732233,-0.87868 -0.732233,-2.3033 0,-3.18198c0.732233,-0.87868 1.91942,-0.87868 2.65165,0" />
                </g>
              </svg>
            </Box>
            <Box pl={[8, 8, 9]}>
              <TextBold as="strong" fontSize={[2, 3, 6]}>
                Oups!
              </TextBold>
              <br />
              <Text as="span" fontSize={[2, 3, 4]} mt={2}>
                Something went wrong. This is very awkward.
              </Text>
            </Box>
          </FormMessage>
        </Box>
      )}
    </>
  )
}
