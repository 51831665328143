// import styled from "@emotion/styled"
import { graphql } from "gatsby"
import Img, { FixedObject, FluidObject } from "gatsby-image"
import * as React from "react"
import { Footer, Header, SEO } from "../../components"
import {
  Box,
  Container,
  LinkAnchor,
  Heading,
  Label,
  Text,
  TextBold,
  Title,
} from "../../elements"
import { Colors } from "../../materials"
import { SectionCommunity } from "../../partials/SectionCommunity"

export const query = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "pages/talks/react-and-accessibility/slides" }
      }
      sort: { fields: [name] }
    ) {
      nodes {
        childImageSharp {
          fixed(width: 600) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  }
`

interface Props {
  data: {
    allFile: {
      nodes: {
        name: string
        childImageSharp: {
          fluid: FluidObject & { presentationWidth: number }
          fixed: FixedObject
        }
      }[]
    }
  }
}

const Slide: React.FC<{ props: Props; number: number }> = ({
  props,
  number,
  children,
}) => (
  <Container
    id={`slide-${number}`}
    pt={9}
    maxWidth="l"
    display="flex"
    flexWrap="wrap"
    mx={-4}
  >
    <Box px={4} width={[1, 1, 2 / 3]}>
      <Img
        fluid={props.data.allFile.nodes[number - 1].childImageSharp.fluid}
        alt={`Slide ${number} screenshot`}
        style={{ width: "100%" }}
      />
      <Box mt={2} textAlign="right">
        <LinkAnchor href={`#slide-${number}`}>Slide {number}</LinkAnchor>
      </Box>
    </Box>
    <Box px={4} width={[1, 1, 1 / 3]} py={2} maxWidth="copy">
      {children}
    </Box>
  </Container>
)

const TITLE = "React and Accessibility Tips [Slides + Article] - Aditus"
const DESCRIPTION = `Tips and best practices on React and Accessibility. Examples include react router, form validation, amongst others.`

const ReactZurichAccessibilityPage: React.FC<Props> = props => (
  <>
    <Header />
    <SEO
      title={TITLE}
      description={DESCRIPTION}
      keywords="accessibility, a11y, react, tips, guide, best practices"
    >
      <meta property="fb:app_id" content="824671391240758" />
      <meta
        property="og:url"
        content="https://www.aditus.io/talks/react-and-accessibility/"
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={TITLE} />
      <meta
        property="og:image"
        content="https://www.aditus.io/social/talks-react-accessibility.png"
      />
      <meta
        property="og:image:alt"
        content="Image with text react and accessibility, next to the Aditus symbol"
      />
      <meta property="og:description" content={DESCRIPTION} />
      <meta property="og:site_name" content="Aditus" />
      <meta property="og:locale" content="en_US" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@aditus_a11y" />
      <meta
        name="twitter:url"
        content="https://www.aditus.io/talks/react-and-accessibility/"
      />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta
        name="twitter:image"
        content="https://www.aditus.io/social/talks-react-accessibility.png"
      />
      <meta
        name="twitter:image:alt"
        content="Image with text react and accessibility, next to the Aditus symbol"
      />
    </SEO>
    <Box as="main" bg={Colors.grays.cool[10] as string} pt={8} pb={11}>
      <Box mb={-7} />
      <Slide number={1} props={props}>
        <Box mb={-3} />
        <Title css={{ color: Colors.oc.blue[8] }}>
          React and Accessibility
        </Title>
        <dl>
          <Box as="dt">
            <Label as="strong">Event</Label>
          </Box>
          <Box as="dd" m={0}>
            <LinkAnchor
              href="https://www.meetup.com/Zurich-ReactJS-Meetup/events/259591890/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React Zurich Meetup
            </LinkAnchor>
          </Box>
          <Box as="dt" mt={3}>
            <Label as="strong">Date</Label>
          </Box>
          <Box as="dd" m={0}>
            <time dateTime="2019-04-03">
              April 3<sup>rd</sup>, 2019
            </time>
          </Box>
          <Box as="dt" mt={3}>
            <Label as="strong">Hosted by</Label>
          </Box>
          <Box as="dd" m={0}>
            <LinkAnchor
              href="https://www.ti8m.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ti&m AG
            </LinkAnchor>
          </Box>
          <Box as="dt" mt={3}>
            <Label as="strong">Slides</Label>
          </Box>
          <Box as="dd" m={0}>
            <LinkAnchor
              href="https://www.aditus.io/talks/React-Accessibility-React-Zurich.pdf"
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Slides (1.4MB PDF)
            </LinkAnchor>
          </Box>
        </dl>
      </Slide>
      <Slide number={2} props={props} />
      <Slide number={3} props={props}>
        <Heading color={Colors.oc.blue[8]}>
          Why should we care about accessibility (
          <abbr title="accessibility">A11Y</abbr>)?
        </Heading>
        <Text mt={3}>
          I used to think of accessibility as developing websites for blind
          people. I couldn&#39;t be more wrong.
        </Text>
        <Text mt={2}>
          Accessibility impacts more people than we think. There are around 1.3
          billion people with disabilities in the world. The equivalent to the
          whole chinese market.
        </Text>

        <Text mt={2}>
          There are several&nbsp;
          <LinkAnchor
            href="https://www.w3.org/WAI/people-use-web/abilities-barriers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            types of barrier that impact users on the web
          </LinkAnchor>
          :
        </Text>
        <Box mt={1} />
        <ul>
          <li>auditory disabilities</li>
          <li>cognitive disabilities</li>
          <li>physical disabilities</li>
          <li>speech disabilities</li>
          <li>visual disabilities</li>
          <li>
            <LinkAnchor
              href="https://www.w3.org/TR/wai-age-literature/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aging
            </LinkAnchor>
          </li>
        </ul>
        <Box mt={1} />
        <Text>
          {/* TODO check and add */}
          Additionally, some people might be experiencing temporary impairments
          that may occur due to an accident, surgery, or medication.
        </Text>
      </Slide>
      <Slide number={4} props={props}>
        <Text>
          Another good reason to invest in accessibility is compliance or
          certification.
        </Text>
        {/* TODO: target blank */}
        <Text mt={2}>
          Most countries already require some sort of accessibility compliance
          with&nbsp;
          <LinkAnchor
            href="https://www.w3.org/TR/WCAG20/"
            target="_blank"
            rel="noopener noreferrer"
          >
            WCGA 2.0
          </LinkAnchor>
          , or&nbsp;
          <LinkAnchor
            href="https://www.section508.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Section 508
          </LinkAnchor>
          , or&nbsp;
          <LinkAnchor
            href="https://www.ada.gov/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ADA
          </LinkAnchor>
          . In some countries, regulation also forces private companies to
          adhere to accessibility standards, such as Norway.
        </Text>
        <Text mt={2}>
          Now is a good time for you to start investing in accessibility.
        </Text>
      </Slide>
      <Slide number={5} props={props}>
        <Text>
          Building more accessible web applications and websites is easier than
          you think.
        </Text>
        <Text mt={2}>
          Web technologies are getting more accessible, and there are a ton
          of&nbsp;
          <LinkAnchor
            href="https://www.w3.org/WAI/ER/tools/"
            target="_blank"
            rel="noopener noreferrer"
          >
            accessibility tools
          </LinkAnchor>
          &nbsp;that developers can use. It has never been easier to start.
        </Text>
        <Text mt={2}>However, most websites have accessibility errors.</Text>
      </Slide>
      <Slide number={6} props={props} />
      <Slide number={7} props={props} />
      {/* <Slide number={8} props={props}>
        
      </Slide>
      <Slide number={9} props={props}>
        
      </Slide> */}
      <Container maxWidth="L" mt={8}>
        <Box bg={Colors.oc.yellow[0]} p={4} display="inline-block">
          <TextBold color={Colors.oc.gray[8]}>
            (2 slides were omitted from original talk)
          </TextBold>
        </Box>
      </Container>
      <Slide number={10} props={props}>
        <Text>
          The&nbsp;
          <LinkAnchor
            href="https://webaim.org/projects/million/"
            target="_blank"
            rel="noopener noreferrer"
          >
            WebAIM Million
          </LinkAnchor>
          &nbsp;evaluated the top 1 million homepages using automated testing
          tools.
        </Text>
        <Text mt={2}>
          Automated accessibility testing is not perfect. In fact, it only
          catches around 30% of all accessibility errors (you should always
          perform&nbsp;
          <LinkAnchor
            href="https://www.smashingmagazine.com/2018/09/importance-manual-accessibility-testing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            manual tests
          </LinkAnchor>
          &nbsp; your website).
        </Text>
        <Text mt={4}>
          But even so, the study found that almost 98% of websites had
          accessibility issues.
        </Text>
        <Text mt={2}>
          Websites using React had, on average, 10% more accessibility problems.
        </Text>
        <Text mt={8}>
          We are preventing people from using our websites by making them
          inaccessible.
        </Text>
        <Text mt={2}>
          We need to do better as web developers. We need to create more
          inclusive websites because this part is of our job.
        </Text>
      </Slide>
      <Slide number={11} props={props} />
      <Slide number={12} props={props} />
      <Slide number={13} props={props}>
        <Text>
          We will see some accessibility tips and best practices in React.
          However, most of this accessibility guidelines applies to other
          frontend frameworks. This is not meant to be a React vs Vue vs Angular
          type of argument.
        </Text>
        <Text>
          It contains structural patterns, interaction patterns and application
          patterns.
        </Text>
      </Slide>

      <Slide number={14} props={props} />
      <Slide number={15} props={props}>
        <Heading color={Colors.oc.blue[8]}>
          Accessible Structural Patterns
        </Heading>
        <Heading as="h3" fontSize={[4]} color={Colors.oc.blue[8]} mt={3}>
          HTML5 Landmarks
        </Heading>
        <Text mt={3}>
          People without any visual disability are used to open a website and
          see all the content at a glance. We can see the header, navigation
          links, and we can already see where and what the main content of the
          page is. We immediately know what the page is all about.
        </Text>
        <Text mt={2}>
          People who navigate the internet using screen reader software have a
          completely different experience.
        </Text>
        <Text mt={4}>
          Learn how to&nbsp;
          <LinkAnchor
            href="https://webaim.org/articles/screenreader_testing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            test your website with a screen reader
          </LinkAnchor>
          .
        </Text>
      </Slide>
      <Container maxWidth="L" mt={8}>
        <Box bg={Colors.oc.yellow[0]} p={4} display="inline-block">
          <TextBold color={Colors.oc.gray[8]}>
            (7 slides were omitted from original talk)
          </TextBold>
        </Box>
      </Container>
      <Slide number={24} props={props}>
        <Text>
          There are several types of landmarks, and they usually have HTML5
          equivalent tags.
        </Text>
        <Text mt={2}>
          For example, if you use the <code>header</code> HTML5 tag, and it is
          not within any <code>article</code>, <code>aside</code>,{" "}
          <code>main</code>, or <code>section</code>, it will be inferred as
          having the <code>header</code> role by screen readers.
        </Text>
        <Text mt={2}>
          The <code>main</code> tag, which you should only have one per page,
          will also be automatically inferred as the <code>main</code> landmark.
        </Text>
        <Text mt={2}>
          The <code>footer</code> tag will also be inferred as the{" "}
          <code>{"contentinfo"}</code> landmark if not present within any{" "}
          <code>article</code>, <code>aside</code>, <code>main</code>, or{" "}
          <code>section</code> tag.
        </Text>
        <Text mt={2}>
          The <code>nav</code> tag will be automatically inferred as the{" "}
          <code>navigation</code> landmark.
        </Text>
        <Text mt={2}>
          The <code>section</code> tag will be automatically inferred as the{" "}
          <code>region</code> landmark.
        </Text>
        <Text mt={2}>
          To provide more information to screen reader users you should label
          your section landmarks.
        </Text>
        <Text mt={2}>
          The <code>form</code> tag will be automatically inferred as the{" "}
          <code>form</code> landmark.
        </Text>
        <Text mt={2}>
          The <code>aside</code> tag will be automatically inferred as the{" "}
          <code>complementary</code> landmark.
        </Text>
        <Text mt={2}>
          There is one more landmark type, the <code>search</code> landmark,
          that has no HTML5 equivalent.
        </Text>
      </Slide>
      <Slide number={25} props={props}>
        <Text>
          Let&#39;s see how can we use landmarks to create more accessible
          websites.
        </Text>
      </Slide>
      <Slide number={26} props={props}>
        <Text>
          In this example, the <code>header</code>, <code>main</code>, and{" "}
          <code>footer</code> elements are automatically assigned their landmark
          roles.
        </Text>
      </Slide>
      <Slide number={27} props={props}>
        <Text>
          We can then override when needed. In this case, there is a search form
          on the page. We can enhance this form by passing the attribute{" "}
          <code>{'role="search"'}</code>, making this a <code>search</code>{" "}
          landmark.
        </Text>
      </Slide>
      <Slide number={28} props={props}>
        <Text>
          Because we have multiple <code>nav</code> elements on the page, we can
          label each one using the attribute{" "}
          <code>{'aria-label="primary"'}</code> and{" "}
          <code>{'aria-label="secondary"'}</code> correspondently.
        </Text>
        <Text mt={2}>
          It&#38;s redundant to write{" "}
          <code>{'aria-label="primary navigation"'}</code> as screen-readers
          already know that the landmark is of type <code>navigation</code>.
          Otherwise, the screen-reader announces it as{" "}
          <em>&ldquo;primary navigation navigation&rdquo;</em>, which is quite
          annoying.
        </Text>
      </Slide>
      <Slide number={29} props={props}>
        <Text>
          We should also make use of the <code>aria-label</code> attribute in
          the search input field. Visually, our design is enough to convey the
          meaning of the form, but screen reader users will have no information
          for this field.
        </Text>
      </Slide>
      <Slide number={30} props={props}>
        <Text>
          Similarly, we can make use of the attribute{" "}
          <code>{"aria-labelledby"}</code> and reference an ID of another
          element in the page. This element acts as a label of the{" "}
          <code>section</code>.
        </Text>
      </Slide>
      <Slide number={31} props={props}>
        <Heading as="h3" fontSize={[4]} mt={1} color={Colors.oc.blue[8]}>
          Forms Accessibility
        </Heading>
        <Text mt={2}>
          Everyone hates forms. The experience for screen reader users is even
          worst.
        </Text>
        <Text mt={2}>Here are 3 tips to make forms accessible.</Text>
      </Slide>
      <Container maxWidth="L" mt={8}>
        <Box bg={Colors.oc.yellow[0]} p={4} display="inline-block">
          <TextBold color={Colors.oc.gray[8]}>
            (1 slide was omitted from original talk)
          </TextBold>
        </Box>
      </Container>
      <Slide number={33} props={props}>
        <Heading as="h5" fontSize={[4]} color={Colors.oc.blue[8]}>
          Accessibility Tip #1
        </Heading>
        <Text mt={2}>
          Always label your <code>input</code> elements.
        </Text>
        <Text mt={2}>
          The easiest way to do this, is to surround your <code>input</code>{" "}
          element with a <code>label</code>, and add the labelling text as a
          sibling of the <code>input</code>.
        </Text>
      </Slide>
      <Slide number={34} props={props}>
        <Text>
          Another way to label <code>input</code> elements, is to make use of
          the <code>htmlFor</code> attribute (in JSX, the html attribute{" "}
          <code>for</code> is used as <code>htmlFor</code>).
        </Text>
        <Text mt={2}>
          The value of this attribute needs to be a valid ID pointing to an{" "}
          <code>input</code> element with that ID.
        </Text>
      </Slide>
      <Slide number={35} props={props}>
        <Text>
          But careful with this approach in React. If you are writing a
          component, you don&#39;t know where and when it is going to be used.
          You can&#39;t assume that it will only be used once on the page.
        </Text>
        <Text mt={2}>
          If you are using the component, be sure to make this IDs unique. I
          recommend you use the first approach, as to avoid this issue
          altogether.
        </Text>
      </Slide>
      <Slide number={36} props={props}>
        <Text>
          Labelling an input is extremely important. Even if your designer
          didn&#39;t visually put a label there.
        </Text>
        <Text mt={2}>
          I&#39;m talking about input fields that visually look like their label
          is the placeholder.
        </Text>
        <Text mt={2}>
          Screen readers don&#39;t consider the <code>placeholder</code>{" "}
          attribute as a label, and it won&#39;t be announced. Nonetheless, many
          of those <code>input</code> elements on the are implemented like this.
        </Text>
      </Slide>
      <Slide number={37} props={props}>
        <Text>
          To make this <code>input</code> accessible in React you can make use
          of a nice <code>{"<VisuallyHidden />"}</code> component.
        </Text>
        <Text mt={2}>
          With only a few lines of CSS, we can hide the element in the screen
          but still make it announced by screen readers.
        </Text>
      </Slide>
      <Slide number={38} props={props}>
        <Text>
          You can use it as you would use a <code>label</code> element, except,
          nothing will be displayed on the website.
        </Text>
      </Slide>
      <Slide number={39} props={props}>
        <Heading as="h5" fontSize={[4]} color={Colors.oc.blue[8]}>
          Accessibility Tip #2
        </Heading>
        <Text mt={2}>
          Another way to label your inputs (or anything else) is to use{" "}
          <code>aria-label</code> or <code>aria-labelledby</code> attributes.
        </Text>
      </Slide>
      <Slide number={40} props={props}>
        <Heading as="h3" fontSize={[4]} color={Colors.oc.blue[8]}>
          Icons accessibility
        </Heading>
        <Text mt={2}>
          Another common element on websites, are icon links or icon buttons
          icon. In this example, with an arrow icon.
        </Text>
      </Slide>
      <Slide number={41} props={props}>
        <Text>
          The icon in this scenario is only used as a decorative element, and it
          doesn&#39;t constitute any type of textual meaning we want our
          screen-reader users to be read.
        </Text>
        <Text mt={2}>
          We can make use of an <code>{'aria-hidden="true"'}</code> attribute,
          to hide the element from the accessibility tree. This makes
          screen-readers not announce the element for our users.
        </Text>
      </Slide>
      <Slide number={42} props={props}>
        <Text>
          A similar example is when we have links that are only made out of
          icons. For example, we often see social links in the footer of
          websites, where the logo of these social networks is used for the link
          itself.
        </Text>
        <Text>
          And while this is effective for people without visual disabilities,
          people who can&#39;t see the icon have no idea where the links points
          to.
        </Text>
      </Slide>
      <Slide number={43} props={props}>
        <Text mb={2}>We can make accessible icon buttons in two steps:</Text>
        <ul>
          <Text as="li">
            hide the link from the accessibility tree, by using{" "}
            <code>{'aria-hidden="true"'}</code> on the element with the image,
            usually an <code>svg</code>.
          </Text>
          <Text as="li">
            provide a text alternative to the link, by using the{" "}
            <code>aria-label</code> attribute on the link it-self.
          </Text>
        </ul>
      </Slide>

      <Slide number={44} props={props} />
      <Container maxWidth="L" mt={8}>
        <Box bg={Colors.oc.yellow[0]} p={4} display="inline-block">
          <TextBold color={Colors.oc.gray[8]}>
            (1 slide was omitted from original talk)
          </TextBold>
        </Box>
      </Container>
      <Slide number={46} props={props}>
        <Heading color={Colors.oc.blue[8]}>
          Accessible Interaction Patterns
        </Heading>
        <Heading as="h3" fontSize={[4]} color={Colors.oc.blue[8]} mt={3}>
          Form Validation
        </Heading>
        <Text mt={1}>
          These examples make use of{" "}
          <LinkAnchor
            href="https://github.com/jaredpalmer/formik"
            target="_blank"
            rel="noopener noreferrer"
          >
            Formik
          </LinkAnchor>
          , a React package that makes form validation a lot easier.
        </Text>
      </Slide>
      <Slide number={47} props={props}>
        <Text>
          One error that we sometimes do is how to associate form errors with
          their inputs.
        </Text>
        <Text mt={2}>
          The right way to do it is to use the attribute{" "}
          <code>{"aria-describedby"}</code>, with the value being the id of the
          error element associated with the input.
        </Text>
        <Text>One note, make sure the IDs are unique within a page.</Text>
      </Slide>
      <Slide number={48} props={props}>
        <Text>There are two ways we can improve form validations.</Text>
        <Text mt={2}>
          The first is to provide live form validation, as the user types. For
          screen-reader users, this means adding the attribute{" "}
          <code>{'aria-live="polite"'}</code> to the error element.
        </Text>
        <Text mt={2}>
          When text changes inside this element, the screen reader will announce
          it.
        </Text>
      </Slide>
      <Slide number={49} props={props}>
        <Text>
          However, for some types of fields, we don&#39;t want the errors to be
          shown or announced as the user types.
        </Text>
        <Text mt={2}>
          Take the example of an email field. When the user starts typing, the
          field is invalid. It will only become a valid email, when the user is
          very close to finishing typing the email address. We don&#39;t want
          the error to appear visually as the user types, or being announced by
          our screen readers.
        </Text>
        <Text mt={2}>
          To change this behavior, we can change the <code>aria-live</code>{" "}
          attribute to <code>assertive</code>. This causes screen readers to
          only announce the error when the user leaves the field by
          &ldquo;un-focusing&rdquo; it.
        </Text>
      </Slide>
      <Slide number={50} props={props}>
        <Heading as="h3" fontSize={[4]} color={Colors.oc.blue[8]}>
          Icon Toggle Buttons
        </Heading>
        <Text mt={2}>
          They are used as <em>&ldquo;on/off&rdquo;</em> switches. Spotify uses
          this in multiple places throughout their web application, including to{" "}
          <em>&ldquo;mute/un-mute&rdquo;</em> the sound.
        </Text>
      </Slide>
      <Container maxWidth="L" mt={8}>
        <Box bg={Colors.oc.yellow[0]} p={4} display="inline-block">
          <TextBold color={Colors.oc.gray[8]}>
            (1 slide was omitted from original talk)
          </TextBold>
        </Box>
      </Container>
      <Slide number={52} props={props}>
        <Text>
          Let&#39;s consider a button responsible for{" "}
          <em>&ldquo;muting/un-muting&rdquo;</em> the sound. Visually, the
          button is only shown with two icons, one when it&#39;s active (sound
          is on) and one when it&#39;s inactive (sound is off).
        </Text>
        <Text mt={2}>
          The first thing we need to do, is to provide a text alternative for
          the button. We can do that by adding the{" "}
          <code>{'aria-label="Mute"'}</code> attribute.
        </Text>
      </Slide>
      <Slide number={53} props={props}>
        <Text>
          The second thing we need to do, is to mark the icon as purely
          decorative, by adding an <code>{'aria-hidden="true"'}</code>{" "}
          attribute.
        </Text>
      </Slide>
      <Slide number={54} props={props}>
        <Text>
          And finally, we need to make this button behave like a toggle and not
          a button. The difference between them is that the toggle has an
          internal state (signalled visually as an icon change) that makes the
          button active/inactive.
        </Text>
        <Text mt={2}>
          We can provide this state to screen reader users by adding an{" "}
          <code>{'aria-pressed="true"'}</code> or{" "}
          <code>{'aria-pressed="false"'}</code>.
        </Text>
        <Text mt={2}>
          As we&#39;re creating an icon button in React, we can abstract this in
          a prop of type <code>boolean</code> (yay, React!).
        </Text>
      </Slide>
      <Slide number={55} props={props} />
      <Slide number={56} props={props}>
        <Heading color={Colors.oc.blue[8]}>
          Accessible Application Patterns
        </Heading>
        <Heading as="h3" fontSize={[4]} color={Colors.oc.blue[8]} mt={3}>
          Accessible React Router
        </Heading>
        <Text mt={2}>
          Most React applications have routing, such as the popular library{" "}
          <LinkAnchor
            href="https://reacttraining.com/react-router/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Router
          </LinkAnchor>
          , which has a few accessibility issues.
        </Text>
        <Text mt={2}>
          When a user navigates to a new page, nothing is announced by screen
          reader users. It&#39;s like nothing even happened.
        </Text>
      </Slide>
      <Slide number={57} props={props}>
        <Text>
          We can fix the react router problem with{" "}
          <strong>focus management</strong>.
        </Text>
        <Text mt={2}>
          When we change to a new page, we need to change the focus to the new
          component that was just mounted.
        </Text>
      </Slide>
      <Slide number={58} props={props}>
        <Text>
          There are a few things that we need to do to achieve this in React.
        </Text>
        <Text mt={2}>
          First, we need to add a <code>{`tabIndex={-1}`}</code>, in order to
          make the container of the new page &ldquo;focusable&rdquo; (it will
          likely be a &ldquo;non-focusable&rdquo; element such as a{" "}
          <code>section</code>).
        </Text>
      </Slide>
      <Slide number={59} props={props}>
        <Text>
          We should also make sure that this element is properly labelled. As
          the new page will likely have an <code>h1</code> element, we can use
          this element to label the container by adding{" "}
          <code>{'id="title"'}</code> attribute to the heading and a{" "}
          <code>{'aria-labelledby="title"'}</code>.
        </Text>
      </Slide>
      <Slide number={60} props={props}>
        <Text>
          Lastly, we need to move the focus to this container element (now a
          &ldquo;focusable&rdquo; element).
        </Text>
        <Text mt={2}>
          To achieve this in React, we first need to create a variable
          containing a <code>ref</code> to the element.
        </Text>
      </Slide>
      <Slide number={61} props={props}>
        <Text>
          And finally, once we have a <code>{"ref"}</code> to the element, we
          can focus the element once the component mounts.
        </Text>
        <Text mt={2}>
          This example uses{" "}
          <LinkAnchor
            href="https://reactjs.org/docs/hooks-intro.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Hooks
          </LinkAnchor>{" "}
          to focus an element by calling <code>{`ref.current.focus()`}</code>{" "}
          inside a <code>useEffect</code>
          callback.
        </Text>
        <Text mt={2}>
          If you&#39;re not using hooks, you can achieve the same by calling
          this inside a <code>componentDidMount</code>.
        </Text>
      </Slide>
      <Slide number={62} props={props}>
        <Heading as="h3" fontSize={[4]} color={Colors.oc.blue[8]}>
          Accessible Notifications
        </Heading>
        <Text mt={2}>
          Web applications need to notify the user something has happened.
          Consider an e-commerce website, we need to notify the user once an
          item has been added to the cart.
        </Text>
        <Text mt={2}>
          We would have a cart icon with a number of items in it displayed. No
          problem with this for people with no visual disabilities, as they can
          not only see the total number of items in the cart at a glance, but
          also see when a new item is added.
        </Text>
      </Slide>
      <Slide number={63} props={props}>
        <Text>
          To make this pattern accessible for people using a screen reader, we
          can start by making use of the <code>{"<VisuallyHidden />"}</code>{" "}
          component to provide a text alternative to this information.
        </Text>
        <Text>
          We can then add two html attributes: <code>{`role=status`}</code> – to
          signal that this component will have live messages being announced –
          and <code>{`aria-live="polite"`}</code> – so that when new content is
          added inside of it, it&#39;s immediately announced without user
          interaction.
        </Text>
      </Slide>
      <Slide number={64} props={props}>
        <Text>
          Another similar pattern, is when we have global flash messages being
          shown the user. This messages would be shown as soon as something
          relevant happens like updating data on a user profile, internet
          connection being lost, etc.
        </Text>
      </Slide>
      <Slide number={65} props={props}>
        <Text>
          Let&#39;s assume we have a component called{" "}
          <code>{"<Announcements />"}</code> that receives a prop{" "}
          <code>{`announcements: string[]`}</code>. This component is
          responsible for showing incoming messages. We can imagine this
          component being used once as part of the application layout.
        </Text>
      </Slide>
      <Slide number={66} props={props}>
        <Text>
          In this example, each message is preceded by an icon showing if
          it&#39;s a success, warning, or error message (note that is good to
          have an icon and not only a color, as we should never rely on color
          alone as some people might suffer from color blindness).
        </Text>
        <Text>
          To convey the same meaning of the icon for people using a screen
          reader, we need to first add an <code>{`aria-hidden="true"`}</code> to
          the image html element.
        </Text>
      </Slide>
      <Slide number={67} props={props}>
        <Text>
          And also provide a text alternative for the icon meaning, using the{" "}
          <code>{"<VisuallyHidden />"}</code> component.
        </Text>
      </Slide>
      <Slide number={68} props={props}>
        <Text>
          Lastly, as new announcements will be shown over time, we need to add
          an <code>{`aria-atomic="true"`}</code> attribute, which makes screen
          readers only announce newly added content.
        </Text>
      </Slide>
    </Box>
    <SectionCommunity hideLastestContent />
    <Footer />
  </>
)

export default ReactZurichAccessibilityPage
