type GoogleAnalyticsEvent =
  | GoogleAnalyticsGenericEvent
  | GoogleAnalyticsFormSubmitEvent

type GoogleAnalyticsFormSubmitEvent = {
  category: "form"
  action: "submit"
  label: string
}

type GoogleAnalyticsGenericEvent = {
  category: string
  action: string
  label: string
}

export function trackEvent(event: GoogleAnalyticsEvent) {
  window.ga &&
    window.ga("send", "event", {
      eventCategory: event.category,
      eventAction: event.action,
      eventLabel: event.label,
    })
}

export function trackFormSubmitEvent(label: string) {
  trackEvent({
    category: "form",
    action: "submit",
    label,
  })
}
