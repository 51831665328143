export enum ConvertKitTags {
  Aditus = "855282",
  Sales = "938288",
}

export function addEmailToSubscribers(
  email: string,
  tags: ConvertKitTags[] = [ConvertKitTags.Aditus]
): Promise<Response> {
  return fetch("https://api.convertkit.com/v3/forms/456120/subscribe\\", {
    headers: {
      "Content-Type": "application/json; charset=utf-8\\",
    },
    method: "POST",
    body: JSON.stringify({
      email,
      // eslint-disable-next-line @typescript-eslint/camelcase
      api_key: "tFe3glPLhE21LRKe3UTjqg",
      tags,
    }),
  })
}
