import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"
import SuperheroMinimal from "../../../static/illustrations/home/superhero-minimal.inline.svg"
import Superhero from "../../../static/illustrations/home/superhero.inline.svg"
import IllustrationScanColors from "../../../static/illustrations/button-contrast-checker/scan-colors.inline.svg"
import {
  Box,
  Container,
  LinkAnchor,
  Heading,
  ImageFluidContainer,
  Label,
  LinkGatsby,
  Pill,
  Text,
  TextBold,
  PillType,
  PillSize,
} from "../../elements"
import { Colors } from "../../materials"
import { SubscriptionForm } from "../SubscriptionForm"

const Splitter = styled("div")((props: { hideMission?: boolean }) =>
  props.hideMission === true
    ? {
        background: `linear-gradient(
      transparent 0%,
      transparent 50%,
      ${Colors.oc.blue[1]} 50%,
      ${Colors.oc.blue[1]} 100%
    );`,
      }
    : {
        background: `linear-gradient(
    ${Colors.tw.red["100"]} 0%,
    ${Colors.tw.red["100"]} 50%,
    ${Colors.oc.blue[1]} 50%,
    ${Colors.oc.blue[1]} 100%
  );`,
      }
)

export const SectionCommunity: React.FC<{
  as?: string
  withHiddenDescription?: boolean
  hideLastestContent?: boolean
  hideMission?: boolean
}> = ({
  as = "section",
  withHiddenDescription = true,
  hideMission = false,
  hideLastestContent = false,
}) => {
  const { talk, contrastThumbnail, lili, joao } = useStaticQuery(graphql`
    fragment thumbnail on File {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    fragment profilePicture on File {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    query {
      talk: file(
        relativePath: {
          eq: "pages/talks/react-and-accessibility/slides/slide.01.png"
        }
      ) {
        ...thumbnail
      }

      contrastThumbnail: file(
        relativePath: { eq: "images/contrast-and-accessibility-thumbnail.png" }
      ) {
        ...thumbnail
      }

      lili: file(relativePath: { eq: "photos/lili.png" }) {
        ...profilePicture
      }

      joao: file(relativePath: { eq: "photos/joao.png" }) {
        ...profilePicture
      }
    }
  `)
  return (
    <>
      {hideMission ? (
        <Box mt={8} />
      ) : (
        <>
          <div id="about-us" />
          <Box
            pt={[7, 8, 8, 9]}
            pb={[10, 10, 11, 11]}
            as={as}
            aria-labelledby="section4"
            bg={Colors.tw.red["100"] as string}
            css={{
              borderTop: `1px solid ${Colors.tw.red["200"]}`,
              position: "relative",
            }}
          >
            <Container
              maxWidth="m"
              display="flex"
              flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
            >
              <Box width={[1, 1, 1, 1]} order={[0, 0, 0, 1]}>
                <Box maxWidth="copy">
                  <Heading
                    color={Colors.oc.blue[9] as string}
                    id="section4"
                    as="h2"
                  >
                    Our mission is to make
                    <br />
                    the web more accessible
                  </Heading>
                  <Box mt={4} />

                  <Text fontSize={[2, 3]}>
                    This is the <strong>right thing to do.</strong> Our goal is
                    to enable you to create accessible products by building
                    meaningful tools, raising awareness, and increasing your
                    teams&#39;s expertise.
                  </Text>
                  <Box mt={5} />
                  <Text fontSize={[2, 3]}>
                    Let's make accessibility time and cost efficient.
                  </Text>
                  <Box mt={5} />
                  <Text fontSize={[2, 3]}>
                    We also provide on-site training and{" "}
                    <LinkAnchor href="mailto:liliana@aditus.io?subject=Accessibility Audit">
                      extensive accessibility audits
                    </LinkAnchor>
                    .
                    <br />
                    We&#39;d love to work with you.
                  </Text>
                </Box>
                {/* END OF COPY */}
              </Box>

              {/* Avatars container */}
              <Box
                mt={[7, 7, 7, 0]}
                width={[1, 1, 1, "auto"]}
                display="flex"
                flexDirection={["column", "row", "row", "column"]}
              >
                <Box width={[1, 1, 1 / 2, 1]} pr={[0, 0, 0, 8]}>
                  <Box display="flex" alignItems="center">
                    <Box flex="0 0 auto" width={[80, 100, 120]} mr={4}>
                      <Img
                        fluid={lili.childImageSharp.fluid}
                        alt="Liliana Medeiros Guerra"
                      />
                    </Box>
                    <Box>
                      <Heading as="p" fontSize={[2, 3]}>
                        <strong>Liliana</strong>
                      </Heading>
                      <Text fontSize={[2, 3]} my={1}>
                        Founder
                      </Text>
                      <LinkAnchor
                        href="mailto:liliana@aditus.io"
                        css={{ lineHeight: 1, display: "block" }}
                      >
                        liliana@aditus.io
                      </LinkAnchor>
                    </Box>
                  </Box>
                </Box>
                <Box m="auto" />
                <Box
                  width={[1, 1, 1 / 2, 1]}
                  pl={[0, 6, 6, 0]}
                  pr={[0, 0, 0, 8]}
                  mt={[6, 0]}
                >
                  <Box display="flex" alignItems="center">
                    <Box flex="0 0 auto" width={[80, 100, 120]} mr={4}>
                      <Img fluid={joao.childImageSharp.fluid} alt="João" />
                    </Box>
                    <Box>
                      <Heading as="p" fontSize={[2, 3]}>
                        <strong>João</strong>
                      </Heading>
                      <Text fontSize={[2, 3]} my={1}>
                        Founder
                      </Text>
                      <LinkAnchor
                        href="mailto:joao@aditus.io"
                        css={{ lineHeight: 1, display: "block" }}
                      >
                        joao@aditus.io
                      </LinkAnchor>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Container>
            {hideLastestContent ? null : (
              <Container maxWidth="m">
                <Box mt={[9, 11]} />
                <Box
                  m={[-5, -4, -6, -7]}
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  <Box width={[1, 4 / 5, 2 / 3, 1 / 2]} p={[5, 6, 6, 7]}>
                    <Label fontSize={[2, 3]}>Latest Talk</Label>
                    <Box mt={4} />
                    <Box display="flex" alignItems="flex-start">
                      <Box
                        bg={Colors.oc.gray[2]}
                        width={[80, 120, 200]}
                        mr={4}
                        flex="0 0 auto"
                      >
                        <Img
                          fluid={talk.childImageSharp.fluid}
                          alt="Thumbnail of the React and Accessibility talk"
                          imgStyle={{ borderRadius: "10px" }}
                        />
                      </Box>
                      <Box>
                        <LinkGatsby
                          to="/talks/react-and-accessibility/"
                          css={{ whiteSpace: "nowrap" }}
                        >
                          React and Accessibility
                        </LinkGatsby>
                        <Text mt={0} fontSize={[2, 3]}>
                          ReactJS Meetup
                        </Text>
                        <Text mt={3} fontSize={[2, 3]}>
                          <time dateTime="2019-04-03">April 2019</time>
                        </Text>
                        <Text fontSize={[2, 3]}>Zurich, Switzerland</Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box width={[1, 4 / 5, 2 / 3, 1 / 2]} p={[5, 6, 6, 7]}>
                    <Label fontSize={[2, 3]}>Tools</Label>
                    <Box mt={4} />
                    <Box display="flex" alignItems="flex-start">
                      <Box
                        bg={Colors.oc.gray[2]}
                        // width={[80, 120, 200]}
                        height={[80, 90, 150]}
                        mr={4}
                        flex="0 0 auto"
                        css={{
                          svg: {
                            width: "auto",
                            height: "100%",
                          },
                        }}
                      >
                        <IllustrationScanColors />
                        {/* <Img
                      fluid={booking.childImageSharp.fluid}
                      alt="Thumbnail of booking.com"
                      imgStyle={{ borderRadius: "10px" }}
                    /> */}
                      </Box>
                      <Box>
                        <Box>
                          <Box
                            fontSize={1}
                            color={Colors.oc.blue[9]}
                            display={["block", "block", "inline"]}
                            mr={1}
                          >
                            <Pill type={PillType.INHERIT} size={PillSize.SMALL}>
                              Free
                            </Pill>
                          </Box>
                          <LinkGatsby to="/button-contrast-checker/" css={{}}>
                            Button Contrast Checker
                          </LinkGatsby>
                        </Box>

                        <Text>
                          Check the contrast of all buttons on your webpage with
                          just one click.
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box width={[1, 4 / 5, 2 / 3, 1 / 2]} p={[5, 6, 6, 7]}>
                    <Label fontSize={[2, 3]}>Articles</Label>
                    <Box mt={4} />
                    <Box display="flex" alignItems="flex-start">
                      <Box
                        bg={Colors.oc.white}
                        borderRadius={10}
                        width={[80, 120, 200]}
                        mr={4}
                        flex="0 0 auto"
                      >
                        <Img
                          fluid={contrastThumbnail.childImageSharp.fluid}
                          alt="Thumbnail of different color combinations and their contrast"
                          imgStyle={{ borderRadius: "10px" }}
                        />
                      </Box>
                      <Box>
                        <LinkGatsby
                          to="/contrast-and-accessibility/"
                          css={{ whiteSpace: "nowrap" }}
                        >
                          Contrast and Accessibility
                        </LinkGatsby>
                        <Text mt={0} fontSize={[2, 3]}>
                          A guide to contrast and accessibility, with examples
                          and tips.
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Container>
            )}
          </Box>
        </>
      )}

      <Splitter as={as} hideMission={hideMission}>
        <Container pb={[6, 0]}>
          <Container
            display="flex"
            flexDirection="column"
            bg={Colors.tw.red["200"]}
            border={`2px solid ${Colors.tw.red["300"]}`}
            color={Colors.oc.black}
            borderRadius="10px"
            pt={[4, 6]}
            pb={[4, 6]}
            css={{ position: "relative" }}
          >
            <ImageFluidContainer
              display={["none", "none", "none", "block"]}
              css={{
                position: "absolute",
              }}
              top={[0, 0, "-30px", "-58px"]}
              left={[0, 0, "620px", "640px"]}
              width={["200px", "200px", "280px", "320px"]}
            >
              <Superhero aria-label="Illustration with a superhero and the text become an accessibility hero next to hit" />
            </ImageFluidContainer>
            <ImageFluidContainer
              display={["block", "block", "block", "none"]}
              css={{
                position: "absolute",
              }}
              top={["-85px", "-105px", "-130px"]}
              right={[0, "-30px", "-30px"]}
              width={["150px", "250px", "230px"]}
            >
              <SuperheroMinimal aria-label="Illustration with a superhero" />
            </ImageFluidContainer>

            <Heading ml={[0, 0, 4]} pr={[7, 0]} fontSize={[5, 6, 7]}>
              Be the first to know when we&#39;re ready
            </Heading>
            <Box maxWidth="copy" ml={[0, 0, 4]}>
              <Box mt={2} />
              <Text fontSize={[2, 3]}>
                We&#39;re building the&nbsp;
                <strong>next-generation accessibility tool</strong>, and this
                can&#39;t be done without you. We&#39;re looking for
                accessibility superheroes.
              </Text>
              <Text mt={4} fontSize={[2, 3]}>
                To be notified when we launch, drop your email below.
              </Text>
            </Box>
            <Box mt={6}>
              <Box maxWidth="70ch">
                <SubscriptionForm />
                {withHiddenDescription ? (
                  <span className="visually-hidden" id="email-description">
                    Leave your email and we&#39;ll get in touch when we launch.
                    You won&#39;t get SPAMed.
                  </span>
                ) : null}
              </Box>
            </Box>
          </Container>
        </Container>
      </Splitter>
    </>
  )
}
